/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo } from 'react';
import cx from 'classnames';
import { find } from 'lodash';

import useQuery from '../../../hooks/useQuery';
import { DateTime } from '@broadleaf/admin-components/dist/common/components/DateTime';
import PlaceholderBlock from '@broadleaf/admin-components/dist/common/components/PlaceholderBlock';
import {
  getCustomerEmail,
  getCustomerName,
  getFulfillmentOption,
  getFulfillmentAddress,
  getParentOrderNumber,
  getSubmitDate
} from '@broadleaf/admin-components/dist/oms/utils/FulfillmentUtils';
import { getFulfillmentStatuses } from '@broadleaf/admin-components/dist/oms/utils/OrderStatusUtils';
import { getAttribute } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import { useFormatDate } from '@broadleaf/admin-components/dist/common';
import { IFulfillmentViewCommon } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import { IOrderFulfillment } from '@broadleaf/admin-components/dist/types/oms';
import Currency from '@broadleaf/admin-components/dist/common/components/Currency';
import {
  getShippingOptionMessages,
  IOrderFulfillmentCommissions
} from '../../../oms/utils/RmFulfillmentUtils';
import { useUserProfile } from '@broadleaf/admin-components/dist/authentication';
import { useFormatNumber } from '@broadleaf/admin-components/dist/common';

const Details: React.FC<DetailsProps> = ({ className, metadata, state }) => {
  const packingSlip = useQuery().has('packingSlip');
  const formatDate = useFormatDate();
  const fulfillment = state.data;

  const hideSellerDetails = useMemo(() => {
    return hasHideSellerDetails(fulfillment);
  }, [fulfillment]);
  if (!fulfillment) {
    return (
      <div className={cx(className, 'tw-mx-auto tw-w-full tw-py-4 md:tw-px-4')}>
        <PlaceholderBlock height="300" width="full" />
      </div>
    );
  }
  const order = fulfillment.order;
  const orderNumber = getParentOrderNumber(fulfillment);
  const submitDate = getSubmitDate(fulfillment);
  const fulfillmentType = fulfillment.type;
  const fulfillmentOption = getFulfillmentOption(fulfillment);
  const fulfillmentAddress = getFulfillmentAddress(fulfillment);
  const customerName = getCustomerName(fulfillment);
  const customerEmail = getCustomerEmail(fulfillment);
  const shipment = fulfillment.shipment;
  // @ts-ignore
  const vendorName =
    fulfillment.fulfillmentItems[0].orderItem.attributes.vendor;

  const paymentType =
    fulfillment.attributes['paymentType'] === 'INVOICE_CREDIT' ||
    fulfillment.attributes['paymentType'] === 'STORE_CREDIT'
      ? 'Invoice'
      : fulfillment.attributes['paymentType'] === 'REVMED_CREDIT'
      ? 'RevMed Credit'
      : fulfillment.attributes['paymentType'] === 'CREDIT_CARD'
      ? 'Credit Card'
      : 'Other';
  const estimatedDeliveryDate = fulfillment.attributes['estimatedDeliveryDate'];
  const shippingOptionMessages = getShippingOptionMessages(fulfillment);

  return (
    <div className={cx(className, 'tw-flex tw-flex-col tw-p-4')}>
      {!packingSlip && (
        <div className="tw-flex tw-flex-row">
          <FulfillmentStatusIndicator metadata={metadata} state={state} />
        </div>
      )}
      <div className="tw-flex tw-flex-col md:tw-flex-row print:tw-flex-row">
        <div className="tw-flex tw-flex-1 tw-flex-col">
          <LabeledText className="tw-flex-1" label="Parent Order Number">
            <span title={orderNumber}>{orderNumber}</span>
          </LabeledText>

          {fulfillmentAddress && (
            <div className="tw-flex tw-flex-col md:tw-flex-row">
              {fulfillmentAddress && (
                <LabeledText
                  className="tw-flex-1"
                  label="Buyer Facility Address"
                >
                  <FulfillmentAddress address={fulfillmentAddress} />
                </LabeledText>
              )}
            </div>
          )}
        </div>

        <div className="tw-flex-1">
          <LabeledText className="tw-flex-1" label="Date Submitted">
            <DateTime value={submitDate} />
          </LabeledText>

          {!(packingSlip && hideSellerDetails) && (
            <LabeledText className="tw-flex-1" label="Seller Facility Name">
              {vendorName}
            </LabeledText>
          )}

          {fulfillmentType === 'SHIP' &&
            fulfillmentOption &&
            fulfillmentOption.description && (
              <LabeledText className="tw-flex-1" label="Delivery Method">
                {fulfillmentOption.description}
                {shippingOptionMessages.length > 0 && (
                  <>
                    <br />
                    {shippingOptionMessages.join(', ')}
                  </>
                )}
              </LabeledText>
            )}

          {fulfillmentType === 'PICKUP' && (
            <LabeledText className="tw-flex-1" label="Fulfillment Method">
              Pickup
            </LabeledText>
          )}

          {shipment && shipment.trackingNumber && shipment.trackingUrl && (
            <LabeledText className="tw-flex-1" label="Tracking Number">
              <div>
                <a
                  className="tw-text-blue-700"
                  href={shipment.trackingUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {shipment.trackingNumber}
                </a>
              </div>
            </LabeledText>
          )}
        </div>
        <div className="tw-flex-1">
          <LabeledText className="tw-flex-1" label="Payment Type">
            {paymentType}
            {fulfillment.attributes['revMedCreditAmount'] && (
              <span className="tw-block tw-text-xs tw-text-gray-600">
                Applied RevMed Credit:{' '}
                <Currency
                  currency="USD"
                  value={fulfillment.attributes['revMedCreditAmount']}
                />
              </span>
            )}
          </LabeledText>
          {fulfillment.attributes['poNumber'] && (
            <LabeledText className="tw-flex-1" label="PO Number">
              {fulfillment.attributes['poNumber']}
            </LabeledText>
          )}
          {estimatedDeliveryDate && (
            <LabeledText className="tw-flex-1" label="Est. Delivery Date">
              {formatDate(estimatedDeliveryDate, {
                weekday: 'long',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'UTC'
              })}
            </LabeledText>
          )}
          {!packingSlip &&
            order?.attributes &&
            'isTaxExempt' in order.attributes && (
              <LabeledText className="tw-flex-1" label="Tax Exempt">
                <div>
                  {order?.attributes['isTaxExempt'] ? 'Yes' : 'No'}{' '}
                  {order?.attributes['taxExemptDocument'] && (
                    <a
                      href={order?.attributes['taxExemptDocument']?.contentUrl}
                      className="tw-inline-block tw-text-xs tw-text-link-600 hover:tw-text-link-700"
                    >
                      View Document
                    </a>
                  )}
                </div>
              </LabeledText>
            )}
        </div>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row"></div>
    </div>
  );
};

export interface DetailsProps extends IFulfillmentViewCommon {
  className?: string;
}

function getFulfillmentCommission(
  commissionSummary: IOrderFulfillmentCommissions,
  email: string
) {
  return commissionSummary?.commissionLineItems
    .filter(cli => cli.email === email)
    .map(cli => cli.amount)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

// purgecss: tw-bg-red-200 tw-text-red-500 tw-text-red-600 tw-bg-blue-200 tw-text-blue-500 tw-text-blue-600 tw-bg-green-200 tw-text-green-500 tw-text-green-600 tw-bg-success-200 tw-text-success-500 tw-text-success-600 tw-bg-orange-200 tw-text-orange-500 tw-text-orange-600
const FulfillmentStatusIndicator = ({ metadata, state }) => {
  const { status, attributes, currency } = state.data;
  const userProfile = useUserProfile();
  const formatNumber = useFormatNumber();
  const commissionAmount = getFulfillmentCommission(
    attributes?.commissionSummary,
    userProfile?.email
  );
  const fulfillmentStatuses = getFulfillmentStatuses(metadata);
  const { label, color } = find(fulfillmentStatuses, {
    value: status
  }) || { label: status, color: 'gray' };
  const completionPercentage = getCompletionPercentage(status);
  return (
    <div className="tw-relative tw-w-full tw-pt-1">
      <div className="tw-mb-2 tw-flex tw-items-center tw-justify-between">
        <div>
          <span
            className={`tw-inline-block tw-rounded-full tw-py-1 tw-px-2 tw-text-xs tw-font-semibold tw-uppercase tw-text-${color}-600 tw-bg-${color}-200`}
          >
            {label}{' '}
            {attributes['statusReason'] && (
              <span className="tw-text-xxs">
                ({attributes['statusReason']})
              </span>
            )}
          </span>
        </div>
        {commissionAmount > 0 && (
          <div>
            <span className="tw-text-sm">Your Commission: </span>
            <span className={`tw-text-xl tw-text-success-500`}>
              {formatNumber(commissionAmount, {
                style: 'currency',
                currency: currency
              })}
            </span>
          </div>
        )}
      </div>
      <div
        className={`tw-mb-4 tw-flex tw-h-2 tw-overflow-hidden tw-rounded tw-text-xs tw-bg-${color}-200`}
      >
        <div
          style={{ width: completionPercentage }}
          className={`tw-flex tw-flex-col tw-justify-center tw-whitespace-nowrap tw-text-center tw-text-white tw-shadow-none tw-bg-${color}-500`}
        />
      </div>
    </div>
  );
};

function getCompletionPercentage(status) {
  switch (status) {
    case 'NEW':
    case 'PENDING_APPROVAL':
      return '0%';
    case 'READY_TO_FULFILL':
      return '12%';
    case 'CAPTURING_PAYMENT':
      return '32%';
    case 'PAYMENT_CAPTURED':
    case 'PAYMENT_CAPTURE_FAILED':
      return '37%';
    case 'FULFILLING':
      return '50%';
    case 'FULFILL_FAILED':
    case 'FULFILLED':
      return '62%';
    case 'DELIVERED':
    case 'DELIVERY_FAILED':
      return '75%';
    case 'BUYER_ACCEPTED':
    case 'BUYER_REJECTED':
    case 'SELLER_PAYOUT_FAILED':
      return '87%';
    case 'COMPLETED':
    case 'CANCELLED':
      return '100%';
    default:
      return '0%';
  }
}

const FulfillmentAddress = ({ address }) => {
  return (
    <ul className="tw-list-none tw-text-sm">
      {address.fullName && address.fullName != address.companyName && (
        <li>{address.fullName}</li>
      )}
      <li>{address.companyName}</li>
      <li>
        {`${address.addressLine1} ${address.addressLine2 || ''} ${
          address.addressLine3 || ''
        }`.trim()}
      </li>
      <li>
        {address.city}, {address.stateProvinceRegion} {address.postalCode}
      </li>
      <li>{address.country}</li>
      {address.phonePrimary && (
        <li>Phone: {address.phonePrimary.phoneNumber}</li>
      )}
    </ul>
  );
};

const LabeledText = ({ children, className, label }) => {
  return (
    <div
      className={cx(
        className,
        'sm:tw-text-normal tw-mb-4 tw-flex tw-flex-col tw-text-sm'
      )}
    >
      <span className="tw-text-xs tw-font-medium tw-uppercase tw-tracking-tight tw-text-gray-600">
        {label}
      </span>
      {children}
    </div>
  );
};

function getPaymentTypes(metadata) {
  return getAttribute(metadata, 'paymentTypes', []);
}

export const hasHideSellerDetails = (entity?: IOrderFulfillment): boolean => {
  if (entity?.attributes?.hideSellerDetails === undefined) {
    return false;
  } else {
    return (
      entity?.attributes?.hideSellerDetails === true ||
      entity?.attributes?.hideSellerDetails === 'true' ||
      entity?.attributes?.hideSellerDetails['value'] === true ||
      entity?.attributes?.hideSellerDetails['value'] === 'true'
    );
  }
};

export default Details;
